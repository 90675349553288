import React from "react"
import NavBar from './NavBar'
import Navigation from "./page-components/homepage-components/Navigation"
import Footer from './page-components/homepage-components/Footer'
import FadeIn from './FadeIn.module.scss'
import { Link } from 'react-router-dom'


import './css/index.scss'

import styles from './TermsOfUse.module.scss'

export default function TermsOfUse() {
  return (
    <>
      <Navigation />
      <NavBar />

      {/* Main resourse - https://www.onlinegambling.com/blackjack/how-to-count-cards/ */}

      <main className={`${styles.main} ${FadeIn.fadeIn}`}>
        <div className={styles.termsOfUse}>
          <h2> Blackjack Crusher’s Terms Of Use </h2>

          <p>PLEASE REVIEW THIS DOCUMENT CAREFULLY BECAUSE IT AFFECTS YOUR RIGHTS</p>
          <p>Welcome to Blackjack Crusher, home of the web’s best card counting training and resources, operated by Blackjack Crusher LLC. (hereafter sometimes referred to as “Blackjack Crusher,” “us,” or “our”). By using Blackjack Crusher’s Services or accessing the BlackjackCrusher.com website (the “Website”) you acknowledge that you have read these Terms of Use (this “Agreement”) and agree to be bound by them and our Privacy Policy found at <Link to='/privacy-policy'>https://www.BlackjackCrusher.com/privacy-policy/</Link>, which is incorporated herein by reference, whether or not you register as a free member (“Registrant”) or become a paid subscriber (“Premium Member” or “Member”). If you wish to become Registrant or a Premium Member and make use of the Blackjack Crusher service (the “Service”), please read these Terms of Use. If you object to anything in this Agreement or the Blackjack Crusher Privacy Policy, do not use the Website or the Service. This Agreement is subject to change by Blackjack Crusher at any time, effective upon posting on the Website, and your use of the Service after such posting will constitute acceptance by you of such changes. These terms of service govern the relationship between you and Blackjack Crusher.</p>

          <p>THE WEBSITE IS ONLY OFFERED AND AVAILABLE TO USERS WHO ARE 18 YEARS OF AGE OR OLDER AND FULLY ABLE AND COMPETENT TO ENTER INTO THE TERMS, CONDITIONS, OBLIGATIONS, AFFIRMATIONS, REPRESENTATIONS, AND WARRANTIES SET FORTH IN THESE TERMS OF USE AND THE PRIVACY POLICY, AND TO ABIDE BY AND COMPLY WITH THESE TERMS OF USE AND THE PRIVACY POLICY. IF YOU DO NOT MEET ALL OF THE FOREGOING REQUIREMENTS, YOU MUST NOT ACCESS OR USE THE WEBSITE.</p>

          <p>Communications. By creating an Account on our service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</p>

          <p>Description of the Service. Blackjack Crusher provides users with access to a rich collection of resources (the “Service”). You also understand that the Service may include certain communications from Blackjack Crusher, such as service announcements, administrative messages and survey invitations and that these communications are considered part of Blackjack Crusher free registration and paid subscriptions. Unless explicitly stated otherwise, any new features that augment or enhance the current Service, including the release of new Blackjack Crusher properties, shall be subject to this Agreement. You are responsible for obtaining access to the Service, and that access may involve third-party fees (such as Internet service provider or airtime charges). addition, you must provide and are responsible for all equipment necessary to access the Service.</p>

          <p>Beta Services. From time to time, Blackjack Crusher may add new features to the Service that are described as “beta” (“Beta Features”). Registrants and Members acknowledge that Beta Features may be untested, non-functional, and/or partly functional features of the Service. If you elect to use a Beta Feature, you do so at your own risk. Notwithstanding anything else in this Agreement to the contrary, Blackjack Crusher does not warrant that the Beta Features will be provided with due care. Do not rely on the Beta Features for any purpose whatsoever. Beta Features may harm and/or interrupt the regular running your software and/or hardware. Beta Features will be considered part of the Service and all provisions of this Agreement relating to the Service will apply to the Beta Features.</p>

          <p>Offensive, Provocative, and Defamatory Content. Personal attacks, defamation, harassment, spam, offensive content, inappropriately aggressive behavior, or other illegal activities are prohibited.</p>

          <p>Member Disputes. You are solely responsible for your interactions with other Blackjack Crusher Registrants and Members. Blackjack Crusher reserves the right, but has no obligation, to monitor disputes between you and other Members.</p>

          <p>Proprietary Rights. Blackjack Crusher owns and retains all proprietary rights in the Website and the Service. The Website contains the copyrighted material, trademarks, and other proprietary information of Blackjack Crusher, and its licensors. Except for that information which is in the public domain or for which you have been given written permission, you may not copy, modify, publish, transmit, distribute, perform, display, or sell any such proprietary information. You may not post, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining the prior written consent of the owner of such proprietary rights.</p>

          <p>No Resale or Redistribution of Service. Except as expressly authorized by Blackjack Crusher, you agree not to reproduce, duplicate, copy, sell, trade, resell, modify, create derivative works, or exploit for any commercial purposes, any portion of the Service or the Software, use of the Service, or access to the Service or computer code that powers the Service (hereafter sometimes “Software”).</p>

          <p>Privacy. Please see our complete Privacy Policy, which is incorporated into this Agreement. Further, you acknowledge, consent and agree that Blackjack Crusher may access, preserve and disclose your account information and content if required to do so by law or in a good faith belief that such access preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce this Agreement; (c) respond to claims that any Content violates the rights of third parties; (d) respond to your requests for customer service; or (e) protect the rights, property or personal safety of Blackjack Crusher, its users and the public.</p>

          <p>Account Access. You must provide your full name, a valid email address, and any other information requested in order to complete subscription process. You must provide a valid email address to become a Registrant. Your login may only be used by one person – a single login shared by multiple people is not permitted. You are responsible for maintaining the security of your account and password. Blackjack Crusher cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</p>

          <p>When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.</p>

          <p>You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account. You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar or obscene. We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</p>

          <p>Unauthorized Users. You must be a human. Accounts registered by “bots” or other automated methods are not permitted.</p>

          <p>Content. Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (“Content”). You are responsible for the Content that you post on or through the Service, including its legality, reliability, and appropriateness.</p>

          <p>By posting Content on or through the Service, You represent and warrant that: (i) the Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.</p>

          <p>You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third party posts on or through the Service. However, by posting Content using the Service you grant us the right and license to use, modify, perform, display, reproduce, and distribute such Content on and through the Service. Blackjack Crusher has the right but not the obligation to monitor and edit all Content provided by users.</p>

          <p>Unauthorized Uses. You must not modify, adapt or hack the Service or modify another website so as to falsely imply that it is associated with the Service or Blackjack Crusher. You may not reverse engineer or reuse source code that is in public view. This includes any and all javascript. The code is Blackjack Crusher’s copyright. You shall not transmit any worms or viruses or any code of a destructive nature. You agree not to modify the Software in any manner or form, nor to use modified versions of the Software, including (without limitation) for the purpose of obtaining unauthorized access to the Service. You agree not to access the Service by any means other than through the interface that is provided by Blackjack Crusher for use in accessing the Service. You acknowledge that the Service is not intended for permanent storage and agree not to use the Service for archiving or back-up purposes.</p>

          <p>Blocking of IP Addresses. In order to protect the integrity of the Service, Blackjack Crusher reserves the right at any time in its sole discretion to block Registrants and Members from certain IP addresses from accessing the Website.</p>

          <h3>Registration and Subscription; Pricing.</h3>

          <p>In order to access additional features and services, including the ability to use many of our tools, you must become a paying Premium Member to the service by agreeing to enter into a subscription with us. Please see our Plans and Pricing page for a description of the current subscription plans and their prices (hereafter “Subscription Policies”).</p>

          <p>Please note that the Subscription Policies that are disclosed to you in subscribing to the service are deemed part of this Agreement. Further, Blackjack Crusher may change Service features and functionality from time to time. For purposes of this Agreement the term “Member” includes all paying subscribers, regardless of renewal term or price level, unless where its usage indicates otherwise. The Subscription Fee is payable in United States dollars (including, if any, all applicable taxes).</p>

          <p>We reserve the right to refuse or cancel your Subscription at any time for reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons. We reserve the right to refuse or cancel your Subscription if fraud or an unauthorized or illegal transaction is suspected.</p>

          <h3>Subscriptions; Charges on your Billing Account.</h3>

          <p>General. You agree to pay Blackjack Crusher the subscription fee (“Subscription Fee”) specified in the Plans and Pricing page during the subscription Period (“Subscription Period”). The Subscription Period varies depending on the plan you sign up for. We currently offer only annual subscriptions.</p>

          <p>Payment Method. A valid credit card is required to subscribe to the Service. Blackjack Crusher bills you through a secure online account (your “Billing Account”) for use of the Service. We have an SSL certificate that prohibits your sensitive credit card information from being intercepted. Your complete billing information is not stored on Blackjack Crusher servers. For your protection, our secure payment gateway partner stores your complete billing information. You agree to pay Blackjack Crusher for all charges at the prices then in effect for any use of the Service by you or other persons (including your agents) using your Billing Account, and you authorize Blackjack Crusher to charge your chosen credit card (your “Payment Method”) for the Service. You agree to make payment using that selected Payment Method. Blackjack Crusher reserves the right to correct any errors or mistakes that it makes even if it has already requested or received payment. If Blackjack Crusher does not receive payment from your Payment Method, you agree to pay all amounts due on your Billing Account on demand.</p>

          <p>Auto-Renewal of Your Subscription Period. You will be entitled to receive the Service only during the subscription period (“Subscription Period”) specified on your Billing Form. All subscriptions will automatically renew at the end of your subscription period until cancelled by you. You will not receive further notice of auto-renewal.</p>

          <p>Recurring Billing. All of our subscription plans use recurring billing. If you elect to pay the Subscription Period annually, you will automatically be charged the Subscription Fee for the subsequent year, unless you cancel the Service before the new Subscription Period begins. By entering into this Agreement, you accept responsibility for all recurring charges prior to cancellation. BLACKJACK Crusher MAY SUBMIT PERIODIC CHARGES (E.B., ANNUALLY) RELATING TO YOUR SUBSCRIPTION WITHOUT FURTHER AUTHORIZATION FROM YOU, UNTIL YOU CANCEL YOUR ACCOUNT. SUCH NOTICE WILL NOT AFFECT CHARGES SUBMITTED BEFORE BLACKJACK Crusher REASONABLY COULD ACT.</p>

          <p>Current Information Required. YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE), AND YOU MUST PROMPTLY NOTIFY BLACKJACK Crusher IF YOUR PAYMENT METHOD IS CANCELED (E.G., FOR LOSS OR THEFT) OR IF YOU BECOME AWARE OF A POTENTIAL BREACH OF SECURITY, SUCH AS UNAUTHORIZED DISCLOSURE OR USE OF YOUR USER NAME OR PASSWORD. CHANGES TO SUCH INFORMATION CAN BE MADE FOLLOWING THE “MANAGE MY SUBSCRIPTION” LINK ON YOUR “EDIT PROFILE” PAGE (YOU MUST BE LOGGED IN TO VIEW THIS PAGE). IF YOU FAIL TO PROVIDE BLACKJACK Crusher ANY OF THE FOREGOING INFORMATION, YOU AGREE THAT BLACKJACK Crusher MAY CONTINUE CHARGING YOU FOR ANY USE OF THE SERVICE UNDER YOUR BILLING ACCOUNT UNLESS YOU HAVE TERMINATED YOUR SUBSCRIPTION FOR THE SERVICE.</p>

          <p>Change in Amount Authorized. If the amount to be charged to your Billing Account varies from the amount you pre-authorized (other than due to the imposition or change in the amount of state sales taxes), you have the right to receive, and Blackjack Crusher shall provide, notice of the amount to be charged and the date of the charge at least 60 days before the scheduled date of the transaction. Notice will be sent to the email address currently associated with your Blackjack Crusher account. You agree that Blackjack Crusher may accumulate charges incurred and submit them as one or more aggregate charges during or at the end of each billing cycle.</p>

          <p>Refunds. Blackjack Crusher will provide new, first-time Members a full refund so long as the Member cancels within fourteen (14) days of first signing up (the “fourteen-day refund period”). Blackjack Crusher will not provide refunds to persons who have previously opened other accounts, whether or not they are using the same or different user names, email addresses, or billing information. Each person or business entity is only entitled to one fourteen-day refund window. Successive sign-ups and refunds are prohibited. After the fourteen-day refund period, there shall be no refunds and your Subscription fee is non-transferable. Accordingly, if you elect to cancel your subscription to the Service during the Subscription Period, you will not receive a refund on the Subscription Fee(s) previously paid to Blackjack Crusher.  If you downgrade your subscription to a lower level, you are not entitled to a cash refund; You may be eligible to pay a reduced, pro-rated amount on your new subscription, depending on when you downgrade.</p>

          <p>Auto-renewal. Your Blackjack Crusher subscription will be automatically extended for successive renewal periods of the same duration as the subscription term originally selected. Blackjack Crusher reserves the right to renew your subscription at the then-current non-promotional subscription rate provided 60 days notice to you of an increase in fees. The notice shall be sent to the email currently associated with your Blackjack Crusher Membership.</p>

          <p>Cancellation. You are solely responsible for properly canceling your account. You can cancel your account at any time by going to your Payment History page (you must be logged in to view this page) and selecting the “Cancel My Subscription” link. An email request to cancel your account is not considered cancellation until you receive a response from the support staff. You may either use the “Cancel My Subscription” link or email site support at info@blackjackCrusher.com to cancel your account. If you paid your subscription with one annual payment and you cancel your Membership, you may use your subscription until the end of your then-current Subscription Period; your subscription will not be renewed after your then-current term expires. However, you won’t be eligible for a prorated refund of any portion of the subscription fee paid for the then current subscription period.</p>

          <p>Collection. Members will pay on all amounts past due, that have not been disputed specifically in writing and in reasonable good faith, an interest charge of one and one-half percent (1.5%) per month computed from the due date of each payment, or the maximum rate permitted by law. Member will be liable for attorneys’ fees and collection costs arising from Blackjack Crusher’s efforts to collect unpaid balances.</p>

          <p>Reaffirmation of Authorization. Your non-termination or continued use of the Service reaffirms that Blackjack Crusher is authorized to charge your Payment Method. Blackjack Crusher may submit those charges for payment and you will be responsible for such charges. This does not waive Blackjack Crusher’s right to seek payment directly from you. Your charges may be payable in advance, in arrears, per usage, or as otherwise described when you initially subscribed to the Service.</p>

          <p>Promotions. Any promotion that provides Member-level access to the Service must be used within the specified time of the trial. You must cancel your subscription before the end of the trial period in order to avoid being charged a Subscription Fee. If you cancel prior to the end of the trial period and are inadvertently charged for a subscription, please contact to have the charges reversed.</p>

          <p>Modifications to Service. Blackjack Crusher reserves the right at any time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that Blackjack Crusher shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.</p>

          <p>Reliance on Content, Advice, Etc. Any advice that may be posted on the Website is for informational and entertainment purposes only and is not intended to replace or substitute for any professional, financial, legal, or other advice. If you have specific concerns or a situation arises in which you require professional advice, you should consult with an appropriately trained and qualified specialist. Blackjack Crusher does not: (i) guarantee the accuracy, completeness, or usefulness of any information on the Service, or (ii) adopt, endorse or accept responsibility for the accuracy or reliability of any opinion, advice, or statement made by any party that appears on the Website. Under no circumstances will Blackjack Crusher or its affiliates be responsible for any loss or damage resulting from your reliance on information or other content posted on the Website or transmitted to or by any Members.</p>

          <p>Disclaimers of Warranties. The Website and the Service are provided “AS-IS” and on an “AS-AVAILABLE” basis. Blackjack Crusher expressly disclaims any warranty of fitness for a particular purpose or non-infringement. Blackjack Crusher cannot guarantee and does not promise any specific results from the use of the Website and/or Service. You agree that you must evaluate, and bear all risks associated with, the use of any Website content and Services, including any reliance on the accuracy, completeness, or usefulness of such content. In this regard, you acknowledge that you may not rely on any content created by Blackjack Crusher or submitted to Blackjack Crusher. Use of the Website and the Services may result in technical malfunction, delay, misdelivery, or other problems with other systems, programs, or computer hardware. Blackjack Crusher cannot and does not guarantee compatibility with other systems and hardware.</p>

          <p>Exclusion. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations and disclaimers of warranties may not apply to you.</p>

          <p>Third-Party Content. Certain content, Products, and services available via the Service may include materials from third parties. In addition, Blackjack Crusher may provide links to certain third-party Websites. You acknowledge and agree that Blackjack Crusher is not responsible for examining or evaluating the content or accuracy of any such third-party material or Websites. Links to other Websites are provided solely as a convenience to you. Because Blackjack Crusher has no control over such sites and resources, you acknowledge and agree that Blackjack Crusher is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any Content, advertising, products or other materials on or available from such sites or resources. You agree that you will not use any third-party materials in a manner that would infringe or violate the rights of any other party, and that Blackjack Crusher is not in any way responsible for any such use by you.</p>

          <p>Limitation on Liability. Except in jurisdictions where such provisions are restricted, in no event will Blackjack Crusher be liable to you or any third person for any direct, indirect, consequential, exemplary, incidental, special or punitive damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if Blackjack Crusher has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the Service; (ii) the cost of procurement of substitute goods and services resulting from your inability to access or obtain any goods, data, information or services through or from the Service; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; or (v) any content posted on the Website or transmitted to Registrants or Members; or (vi) any inaccurate or out-of-date content produced by the tools or published in the guides or blog, or Website; or (vii) any other matter relating to the Service. Nothwithstanding any provision to the contrary, Blackjack Crusher’s liability to you for any cause whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to Blackjack Crusher in the twelve (12) months prior to the claimed injury or damage.</p>

          <p>Indemnity by You. You agree to indemnify and hold Blackjack Crusher, its subsidiaries, affiliates, officers, agents, and other partners and employees, harmless from any loss, liability, claim, or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your use of the Service in violation of this Agreement and/or arising from a breach of this Agreement and/or any breach of your representations and warranties and/or your negligent or willful acts, and/or the violation by you of Blackjack Crusher’s or any third party’s rights, including without limitation privacy rights, other property rights, trade secret, proprietary information, trademark, copyright, or patent rights, and claims for libel slander, or unfair trade practices in connection with the use or operation of the Service. You obligation to indemnify will survive the expiration or termination of this Agreement by either party for any reason.</p>

          <p>No Third Party Beneficiaries. You agree that, except as otherwise expressly provided in this Agreement, there shall be no third party beneficiaries to this Agreement.</p>

          <p>Interstate Nature of Communications on Blackjack Crusher Network. When you register or subscribe with Blackjack Crusher, you acknowledge that in using Blackjack Crusher services to send electronic communications, you will be causing communications to be sent through Blackjack Crusher’s computer networks located in Washington, and other locations in the United States. As a result, and also as a result of Blackjack Crusher’s network architecture and business practices and the nature of electronic communications, even communications that seem to be intrastate in nature can result in the transmission of interstate communications regardless of where you are physically located at the time of transmission. Accordingly, by agreeing to this Agreement, you acknowledge that use of the service results in interstate data transmissions.</p>

          <p>U.S. Export Controls. Software from this Website (the “Software”) is further subject to United States export controls. No Software may be downloaded from the Website or otherwise exported or re-exported (i) into (or to a national or resident of) Cuba, Iraq, Libya, North Korea, Iran, Syria, or any other Country to which the U.S. has embargoed goods; or (ii) to anyone on the U.S. Treasury Department’s list of Specially Designated Nationals or the U.S. Commerce Department’s Table of Deny Orders. By downloading or using the Software, you represent and warrant that you are not located in, under the control of, or a national or resident of any such country or on any such list. Recognizing the global nature of the Internet, you agree to comply with all local rules regarding online conduct and acceptable Content. Specifically you agree to comply with all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside. Blackjack Crusher cannot and will not be liable for any loss or damage arising from your failure to comply with this paragraph.</p>

          <p>Term. This Agreement will remain in full force and effect while you use the Website and/or are a Member. You may cancel your Membership at any time, for any reason. We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms. Blackjack Crusher may terminate your Membership by sending notice to you at the email address you provide in your application for membership, or such other email address as you may later provide to Blackjack Crusher. If Blackjack Crusher terminates your membership in this Service because you have breached this Agreement, you will not be entitled to any refund of unused Subscription Fees. All decisions regarding the termination of accounts shall be made in the sole discretion of Blackjack Crusher. Blackjack Crusher is not required, and may be prohibited, from disclosing a reason for the termination of your account. Even after your membership or subscription is terminated, this Agreement will remain in effect. All terms that by their nature may survive termination of this Agreement shall be deemed to survive such termination. Such termination of the Service will result in the deactivation or deletion of your Account or your access to your Account, and the forfeiture and relinquishment of all Content in your Account. Blackjack Crusher reserves the right to refuse service to anyone for any reason at any time.</p>

          <p>Jurisdiction and Choice of Law. If there is any dispute arising out of the Website and/or the Service, by using the Website, you expressly agree that any such dispute shall be governed by the laws of the State of Washington, without regard to its conflict of laws provisions, and you expressly agree and consent to the exclusive jurisdiction and venue of the state and federal courts of the State of Washington, in King County, for the resolution of any such dispute.</p>

          <p>Other. The failure of Blackjack Crusher to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. If any provision of this Agreement is held invalid, the remainder of this Agreement shall continue in full force and effect. The Terms of Service constitutes the entire agreement between you and Blackjack Crusher and govern your use of the Service, superseding any prior agreements between you and Blackjack Crusher (including, but not limited to, any prior versions of the Terms of Service).</p>

        </div>
      </main>

      <Footer />
    </>
  )
}
